<div class="container-xl" style="min-height: 335px">
  <h1 class="float-left" i18n="page.rbf-replacements">RBF Replacements</h1>
  <div *ngIf="isLoading" class="spinner-border ml-3" role="status"></div>

  <div class="mode-toggle float-right">
    <form class="formRadioGroup">
      <div class="btn-group btn-group-toggle" name="radioBasic">
        <label class="btn btn-primary btn-sm" [class.active]="!fullRbf">
          <input type="radio" [value]="'All'" fragment="" [routerLink]="[]"> All
        </label>
        <label class="btn btn-primary btn-sm" [class.active]="fullRbf">
          <input type="radio" [value]="'Full RBF'" fragment="fullrbf" [routerLink]="[]"> Full RBF
        </label>
      </div>
    </form>
  </div>

  <div class="clearfix"></div>

  <div class="rbf-trees" style="min-height: 295px">
    <ng-container *ngIf="rbfTrees$ | async as trees">
      <div *ngFor="let tree of trees" class="tree">
        <p class="info">
          <span class="type">
            <span *ngIf="tree.mined" class="badge badge-success" i18n="transaction.rbf.mined">Mined</span>
            <span *ngIf="tree.fullRbf" class="badge badge-info" i18n="transaction.full-rbf">Full RBF</span>
          </span>
          <app-time kind="since" [time]="tree.time"></app-time>
        </p>
        <div class="timeline-wrapper" [class.mined]="tree.mined">
          <app-rbf-timeline [replacements]="tree"></app-rbf-timeline>
        </div>
      </div>

      <div class="no-replacements" *ngIf="!trees?.length">
        <p i18n="rbf.no-replacements-yet">there are no replacements in the mempool yet!</p>
      </div>
    </ng-container>
  </div>
  
</div>
