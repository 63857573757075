<div class="container-xl about-page">

  <div class="intro">
    <span style="margin-left: auto; margin-right: -20px; margin-bottom: -20px">&reg;</span>
    <img class="logo" src="/resources/mempool-logo-bigger.png" />
    <div class="version">
      v{{ packetJsonVersion }} [<a href="https://github.com/mempool/mempool/commit/{{ frontendGitCommitHash }}">{{ frontendGitCommitHash }}</a>]
    </div>
  </div>

  <div class="about-text">
    <h5><ng-container i18n="about.about-the-project">The Mempool Open Source Project</ng-container><ng-template [ngIf]="locale.substr(0, 2) === 'en'"> &trade;</ng-template></h5>
    <p i18n>Our mempool and blockchain explorer for the Bitcoin community, focusing on the transaction fee market and multi-layer ecosystem, completely self-hosted without any trusted third-parties.</p>
  </div>

  <video #promoVideo (click)="unmutePromoVideo()" (touchstart)="unmutePromoVideo()" src="/resources/promo-video/mempool-promo.mp4" poster="/resources/promo-video/mempool-promo.jpg" controls loop playsinline [autoplay]="true" [muted]="true">
    <track label="English" kind="captions" srclang="en" src="/resources/promo-video/en.vtt" [attr.default]="showSubtitles('en') ? '' : null">
    <track label="日本語" kind="captions" srclang="ja" src="/resources/promo-video/ja.vtt" [attr.default]="showSubtitles('ja') ? '' : null">
    <track label="中文" kind="captions" srclang="zh" src="/resources/promo-video/zh.vtt" [attr.default]="showSubtitles('zh') ? '' : null">
    <track label="Svenska" kind="captions" srclang="sv" src="/resources/promo-video/sv.vtt" [attr.default]="showSubtitles('sv') ? '' : null">
    <track label="Čeština" kind="captions" srclang="cs" src="/resources/promo-video/cs.vtt" [attr.default]="showSubtitles('cs') ? '' : null">
    <track label="Suomi" kind="captions" srclang="fi" src="/resources/promo-video/fi.vtt" [attr.default]="showSubtitles('fi') ? '' : null">
    <track label="Français" kind="captions" srclang="fr" src="/resources/promo-video/fr.vtt" [attr.default]="showSubtitles('fr') ? '' : null">
    <track label="Deutsch" kind="captions" srclang="de" src="/resources/promo-video/de.vtt" [attr.default]="showSubtitles('de') ? '' : null">
    <track label="Italiano" kind="captions" srclang="it" src="/resources/promo-video/it.vtt" [attr.default]="showSubtitles('it') ? '' : null">
    <track label="Lietuvių" kind="captions" srclang="lt" src="/resources/promo-video/lt.vtt" [attr.default]="showSubtitles('lt') ? '' : null">
    <track label="Norsk" kind="captions" srclang="nb" src="/resources/promo-video/nb.vtt" [attr.default]="showSubtitles('nb') ? '' : null">
    <track label="فارسی" kind="captions" srclang="fa" src="/resources/promo-video/fa.vtt" [attr.default]="showSubtitles('fa') ? '' : null">
    <track label="Polski" kind="captions" srclang="pl" src="/resources/promo-video/pl.vtt" [attr.default]="showSubtitles('pl') ? '' : null">
    <track label="Română" kind="captions" srclang="ro" src="/resources/promo-video/ro.vtt" [attr.default]="showSubtitles('ro') ? '' : null">
    <track label="Português" kind="captions" srclang="pt" src="/resources/promo-video/pt.vtt" [attr.default]="showSubtitles('pt') ? '' : null">
  </video>

  <ng-container *ngIf="false && officialMempoolSpace">
    <h3 class="mt-5">Sponsor the project</h3>
    <div class="d-flex justify-content-center" style="max-width: 90%; margin: 35px auto 75px auto; column-gap: 15px">
      <a href="/sponsor" class="btn" style="background-color: rgba(152, 88, 255, 0.75); box-shadow: 0px 0px 50px 5px rgba(152, 88, 255, 0.75)" i18n="about.community-sponsor-button">Community</a>
      <a href="/enterprise" class="btn" style="background-color: rgba(152, 88, 255, 0.75); box-shadow: 0px 0px 50px 5px rgba(152, 88, 255, 0.75)" i18n="about.enterprise-sponsor-button">Enterprise</a>
    </div>
  </ng-container>

  <div class="enterprise-sponsor" id="enterprise-sponsors">
    <h3 i18n="about.sponsors.enterprise.withRocket">Enterprise Sponsors 🚀</h3>
    <div class="wrapper">
      <a href="https://spiral.xyz/" target="_blank" title="Spiral">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-115 -15 879 679" style="background-color: rgb(27,20,100)" class="image">
        <defs>
        <style>.cls-1{fill:url(#linear-gradient);}</style>
        <linearGradient id="linear-gradient" x1="81.36" y1="311.35" x2="541.35" y2="311.35" gradientUnits="userSpaceOnUse">
          <stop offset="0.18" stop-color="blue"/>
          <stop offset="1" stop-color="#f0f"/>
        </linearGradient>
        </defs>
        <path class="cls-1" d="M326.4,572.09C201.2,572.09,141,503,112.48,445,84.26,387.47,81.89,330.44,81.69,322.31c-4.85-77,41-231.78,249.58-271.2a28.05,28.05,0,0,1,10.41,55.13c-213.12,40.28-204.44,206-204,213,0,.53.06,1.06.07,1.6C137.9,328.74,142.85,516,326.4,516,394.74,516,443,486.6,470,428.63c24.48-52.74,19.29-112.45-13.52-155.83-22.89-30.27-52.46-45-90.38-45-34.46,0-63.47,9.88-86.21,29.37A91.5,91.5,0,0,0,248,322.3c-1.41,25.4,7.14,49.36,24.07,67.49C287.27,406,305,413.9,326.4,413.9c27.46,0,45.52-9,53.66-26.81,8.38-18.3,3.61-38.93-.19-43.33-9.11-10-18.69-13.68-22.48-13-2.53.43-5.78,4.61-8.48,10.92a28,28,0,0,1-51.58-22c14.28-33.44,37.94-42,50.76-44.2,24.78-4.18,52.17,7.3,73.34,30.65s25.51,68.55,10.15,103.22C421.54,432,394.52,470,326.4,470c-36.72,0-69.67-14.49-95.29-41.92C203.64,398.68,189.77,360,192,319.19a149.1,149.1,0,0,1,51.31-104.6c33.19-28.45,74.48-42.87,122.71-42.87,55.12,0,101.85,23.25,135.12,67.23,45.36,60,52.9,141.71,19.66,213.3C495.45,506.92,441.12,572.09,326.4,572.09Z"/>
        </svg>
        <span>Spiral</span>
      </a>
      <a href="https://foundrydigital.com/" target="_blank" title="Foundry">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-10 -10 100 100" class="image">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-186.000000, -2316.000000)">
              <g transform="translate(186.000000, 2316.000000)">
                <rect id="" fill="#023D32" x="-10" y="-10" width="100" height="100" rx="8"></rect>
                <path d="M61.6666667,9.16666667 L61.6666667,17.0041667 L46.2625,17.0041667 C46.2625,17.0041667 44.1666667,16.6666667 44.1666667,18.3333333 L44.1666667,25.8025 L61.6666667,25.8025 L61.6666667,34.7391667 L44.1666667,34.7391667 L44.1666667,70.5575 L31.7825,70.5575 L31.7825,35 L19.1666667,35 L19.1666667,25.595 L31.6666667,25.595 L31.6666667,17.5 C31.6666667,17.5 32.5,9.16666667 40.4166667,9.16666667 L61.6666667,9.16666667 Z" id="Fill-1" fill="#86E2A0"></path>
              </g>
            </g>
          </g>
        </svg>
        <span>Foundry</span>
      </a>
      <a href="https://blockstream.com/" target="_blank" title="Blockstream">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" x="0px" y="0px" viewBox="200 200 600 600" class="image" style="enable-background:new 0 0 1000 1000;background-color: #111316 !important">
          <style type="text/css">
            .st0{fill:#111316;}
            .st1{fill:#00C3FF;}
            .st2{fill:#7EE0FF;}
          </style>
          <path class="st1" d="M659.7,392.3c10.2,14.3,18.4,29.9,24.5,46.4l21.8-7.1c-6.9-18.9-16.4-36.8-28.1-53.1L659.7,392.3z"/>
          <path class="st1" d="M510.6,289.2c-5.8-0.2-11.7-0.2-17.5,0l1.6,22.8c8.8-0.3,17.6-0.1,26.3,0.7c8.7,0.8,17.4,2.2,26,4.2l5.8-22.1  c-9.8-2.3-19.7-3.9-29.7-4.8C519,289.6,514.7,289.3,510.6,289.2z"/>
          <path class="st1" d="M297.1,605.5c-9.1-18.6-15.7-38.3-19.5-58.6l-23.9,3.8c4.2,23,11.6,45.3,22,66.2L297.1,605.5z"/>
          <path class="st1" d="M284.8,375.6l21.2,11.8c10.6-17.8,23.5-34,38.5-48.3l-16.2-18C311.3,337.2,296.7,355.5,284.8,375.6z"/>
          <path class="st1" d="M254.8,453.5l23.8,4.2c4.2-20.3,11.2-39.9,20.7-58.3l-21.2-11.7C267.3,408.5,259.5,430.6,254.8,453.5z"/>
          <path class="st1" d="M409.9,268.8l9.5,22.2c19.3-7.6,39.5-12.5,60.1-14.5l-1.7-24.1C454.5,254.6,431.7,260.1,409.9,268.8z"/>
          <path class="st1" d="M338.5,311.8l16.2,18c15.8-13.4,33.3-24.6,52.1-33.4l-9.5-22.2C376,283.9,356.2,296.6,338.5,311.8z"/>
          <path class="st1" d="M697.1,667.6l-18.9-15.1c-13.4,15.8-28.9,29.7-46,41.4l13,20.5C664.6,701.3,682.1,685.6,697.1,667.6z"/>
          <path class="st1" d="M402.5,710.7c-18.6-9.1-35.9-20.7-51.4-34.5l-16.5,17.7c17.4,15.6,37,28.6,58,38.8L402.5,710.7z"/>
          <path class="st1" d="M755.4,528.2c3.1-32.6-0.2-65.5-9.7-96.8l-23,7.6c13.2,44.4,12.7,91.7-1.3,135.8l22.8,8.1  C749.9,565.2,753.7,546.8,755.4,528.2z"/>
          <path class="st1" d="M614.2,689.2L602,670c-15.1,9-31.3,16-48.3,20.7l5.4,22.2C578.5,707.5,597,699.6,614.2,689.2z"/>
          <path class="st1" d="M314.5,528.8c-1.7-14.2-1.9-28.6-0.5-42.9c0.3-3.5,0.7-6.5,1.2-9.6l-22.5-4c-0.5,3.8-1,7.6-1.4,11.5  c-1.5,16.1-1.3,32.4,0.7,48.5L314.5,528.8z"/>
          <path class="st1" d="M568.2,284.7c19.9,5.8,38.9,14.4,56.4,25.4l13.5-20.2c-19.8-12.5-41.2-22.1-63.7-28.7L568.2,284.7z"/>
          <path class="st1" d="M469.8,755.8l2.3-24.1c-19.5-2.6-38.6-7.8-56.8-15.3l-10.1,22.2C425.8,747.1,447.6,752.9,469.8,755.8z"/>
          <path class="st1" d="M351.3,657.7l15.7-16.6c-12.4-12.5-23.1-26.5-31.8-41.8l-20.3,10.7C324.8,627.4,337.1,643.5,351.3,657.7z"/>
          <path class="st1" d="M649.5,297.7l-13.6,20.2c16.9,12,32,26.3,45.1,42.4l19.4-14.8C685.7,327.2,668.6,311.2,649.5,297.7z"/>
          <path class="st1" d="M672.7,633.2c12-16.1,21.8-33.7,29.1-52.5l-21.5-7.7c-6.4,16.4-15,31.9-25.5,46L672.7,633.2z"/>
          <path class="st2" d="M690.6,449.6l-21.6,7.2c6,20.7,8,42.4,6,63.8c-1.1,11.9-3.4,23.7-6.9,35.2l21.5,7.6c4.1-13.2,6.9-26.9,8.2-40.7  C700.1,498.1,697.6,473.3,690.6,449.6z"/>
          <path class="st2" d="M475.2,698l2.1-22.7c-13.3-2-26.4-5.5-38.9-10.5l-9.4,20.7C443.8,691.5,459.3,695.7,475.2,698z"/>
          <path class="st2" d="M631.8,456.2l20.4-6.9c-4.9-12.9-11.4-25.2-19.4-36.6l-17.1,13C622.3,435.2,627.7,445.4,631.8,456.2z"/>
          <path class="st2" d="M508.4,345.7h-11.2l1.5,21.4c11.5-0.3,22.9,0.7,34.2,3.2l5.5-20.7c-6.8-1.5-13.6-2.6-20.5-3.2  C514.8,346.1,511.6,345.9,508.4,345.7z"/>
          <path class="st2" d="M335.5,403.8l20,11.1c7.5-12.4,16.5-23.7,26.9-33.8L367,364.1C354.8,375.9,344.2,389.2,335.5,403.8z"/>
          <path class="st2" d="M553.8,339.5c13.8,4.2,27.1,10.2,39.4,17.7l12.7-19c-14.4-8.9-30-15.8-46.2-20.7L553.8,339.5z"/>
          <path class="st2" d="M635.9,394.5l18.1-13.8c-10.7-13.2-23.2-24.9-36.9-34.8l-12.7,19C616.2,373.4,626.7,383.3,635.9,394.5z"/>
          <path class="st2" d="M611.5,584.6l16.8,13.4c8.2-11.2,14.9-23.3,20.1-36.2l-20.2-7.2C623.8,565.2,618.2,575.3,611.5,584.6z"/>
          <path class="st2" d="M389.9,635.1l-15.6,16.6c12.8,11.2,26.9,20.7,42.2,28.2l9.4-20.7C412.9,652.8,400.8,644.6,389.9,635.1z"/>
          <path class="st2" d="M369.2,520.2c-1-9.7-1.1-19.5-0.2-29.2c0.2-1.7,0.4-3.5,0.6-5.1l-21.1-3.8c-0.3,2.3-0.6,4.6-0.8,6.9  c-1.1,11.5-0.9,23,0.3,34.5L369.2,520.2z"/>
          <path class="st2" d="M333.6,538l-22.6,3.5c3.2,16.7,8.6,33,16,48.3l20.2-10.7C340.9,566,336.4,552.2,333.6,538z"/>
          <path class="st2" d="M601.7,646.3l12.3,19.2c14-9.6,26.7-21,37.7-33.8l-17.9-14.2C624.4,628.4,613.6,638.1,601.7,646.3z"/>
          <path class="st2" d="M348.8,426.9l-19.9-11c-7.8,15.1-13.5,31.2-17,47.8l22.5,4C337.4,453.5,342.2,439.8,348.8,426.9z"/>
          <path class="st2" d="M540.6,636.9l5,20.7c13.3-3.8,26.1-9.2,38.1-16.2l-11.6-18.1C562.2,629,551.6,633.6,540.6,636.9z"/>
          <path class="st2" d="M384,573.5l-19,9.9c6.9,12,15.4,23,25.1,32.9l14.8-15.7C396.9,592.4,389.9,583.3,384,573.5z"/>
          <path class="st2" d="M496.7,677.1c-1.9,0-3.8-0.2-5.7-0.4l-2.1,22.7c17.9,1.3,35.9,0.1,53.4-3.5l-5.3-22.2  C523.8,676.5,510.2,677.6,496.7,677.1z"/>
          <path class="st2" d="M377.3,354.9l15.3,16.9c11.1-9.3,23.3-17.1,36.4-23.3l-9-21C404.6,334.7,390.3,343.9,377.3,354.9z"/>
          <path class="st2" d="M432.7,322.1l9,21c13.5-5.2,27.6-8.7,42-10.3L482,310C465.1,311.9,448.5,315.9,432.7,322.1z"/>
          <path class="st1" d="M490.3,757.5c21.5,0.7,43-1.1,64.2-5.2l-5-23.3c-18.3,3.8-37,5.3-55.8,4.6c-3,0-5.2-0.4-8.2-0.6l-2.1,24.4  c2.3,0.1,4.6,0.1,6.9,0L490.3,757.5z"/>
        </svg>
        <span>Blockstream</span>
      </a>
      <a href="https://unchained.com/" target="_blank" title="Unchained">
        <svg id="Layer_1" width="78" height="78" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.68 156.68"><defs><style>.cls-unchained-1{fill:#fff;}</style></defs><path class="cls-unchained-1" d="m78.34,0C35.07,0,0,35.07,0,78.34s35.07,78.34,78.34,78.34,78.34-35.07,78.34-78.34S121.6,0,78.34,0ZM20.23,109.5c-4.99-9.28-7.81-19.89-7.81-31.16C12.42,41.93,41.93,12.42,78.34,12.42c33.15,0,60.58,24.46,65.23,56.32h-37.48c-45.29,0-71.19,20.05-85.85,40.76Zm58.11,34.76c-12.42,0-24.04-3.44-33.96-9.41,3.94-8.85,9.11-18.7,15.84-28.9,20.99-31.8,52.2-31.19,76.49-31.19h7.45c.06,1.18.1,2.38.1,3.58,0,36.41-29.51,65.92-65.92,65.92Z"/><path class="cls-unchained-1" d="m91.98,42.4l-3.62-1.18c-3.94-1.29-7.03-4.38-8.32-8.32l-1.18-3.63c-.13-.39-.68-.39-.81,0l-1.18,3.63c-1.29,3.94-4.38,7.03-8.32,8.32l-3.62,1.18c-.39.13-.39.68,0,.81l3.62,1.18c3.94,1.29,7.03,4.38,8.32,8.32l1.18,3.63c.13.39.68.39.81,0l1.18-3.63c1.29-3.94,4.38-7.03,8.32-8.32l3.62-1.18c.39-.13.39-.68,0-.81Z"/></svg>
        <span>Unchained</span>
      </a>
      <a href="https://gemini.com/" target="_blank" title="Gemini">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="360" height="360" viewBox="0 0 360 360" class="image">
          <rect style="fill: black" width="360" height="360" />
          <g transform="matrix(0.62 0 0 0.62 180 180)">
            <path style="fill: rgb(0,220,250)" transform=" translate(-162, -162)" d="M 211.74 0 C 154.74 0 106.35 43.84 100.25 100.25 C 43.84 106.35 1.4210854715202004e-14 154.76 1.4210854715202004e-14 211.74 C 0.044122601308501076 273.7212006364817 50.27879936351834 323.95587739869154 112.26 324 C 169.26 324 217.84 280.15999999999997 223.75 223.75 C 280.15999999999997 217.65 324 169.24 324 112.26 C 323.95587739869154 50.278799363518324 273.72120063648174 0.04412260130848722 211.74 -1.4210854715202004e-14 z M 297.74 124.84 C 291.9644950552469 162.621439649343 262.2969457716857 192.26062994820046 224.51 198 L 224.51 124.84 z M 26.3 199.16 C 31.986912917108594 161.30935034910615 61.653433460549415 131.56986937804106 99.48999999999998 125.78999999999999 L 99.49 199 L 26.3 199 z M 198.21 224.51 C 191.87736076583954 267.0991541201681 155.312384597087 298.62923417787493 112.255 298.62923417787493 C 69.19761540291302 298.62923417787493 32.63263923416048 267.0991541201682 26.3 224.51 z M 199.16 124.83999999999999 L 199.16 199 L 124.84 199 L 124.84 124.84 z M 297.7 99.48999999999998 L 125.78999999999999 99.48999999999998 C 132.12263923416046 56.90084587983182 168.687615402913 25.37076582212505 211.745 25.37076582212505 C 254.80238459708698 25.37076582212505 291.3673607658395 56.900845879831834 297.7 99.49 z" stroke-linecap="round" />
          </g>
        </svg>
        <span>Gemini</span>
      </a>
      <a href="https://bullbitcoin.com/" target="_blank" title="Bull Bitcoin">
        <svg aria-hidden="true" class="image" viewBox="0 -5 40 40" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#a)" fill-rule="evenodd" clip-rule="evenodd" fill="#e21924">
            <path d="M21.92 14.59a1.18 1.18 0 0 0-1.18-1.18h-1.82v2.36h1.82a1.18 1.18 0 0 0 1.18-1.18ZM21 17.07h-2v2.45h2a1.23 1.23 0 1 0 0-2.45Z"/>
            <path d="M36.43 0 35 5.59l-8 2.64-2.43-3.61-4.74 2.05-4.74-2.05-2.43 3.61-8-2.64L3.21 0 0 7.86l7.89 5.86-5.56 4 5.56 1.12 2.69-.49v3.17l3.59 4.38.68 3.19 5 2.87 5-2.87.68-3.19 3.59-4.38v-3.17l2.7.49 5.56-1.12-5.56-4 7.89-5.86zM24.69 18.45a2.5 2.5 0 0 1-2.5 2.5h-1.11v1.56h-1.26V21h-.9v1.56h-1.27V21H15.3v-1.42h.64a.9.9 0 0 0 .9-.9V14.3a.901.901 0 0 0-.9-.91h-.64V12h2.35v-1.5h1.27V12h.9v-1.5h1.26V12h.68A2.269 2.269 0 0 1 24 14.31a2.25 2.25 0 0 1-.92 1.82 2.52 2.52 0 0 1 1.58 2.32z"/>
          </g>
          <defs>
            <clipPath id="a"><path fill="#fff" d="M0 0h160v32H0z"/></clipPath>
          </defs>
        </svg>
        <span>Bull Bitcoin</span>
      </a>
      <a href="https://exodus.com/" target="_blank" title="Exodus">
        <svg width="80" height="80" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="250" cy="250" r="250" fill="#1F2033"/>
          <g clip-path="url(#clip0_2_14)">
            <path d="M411.042 178.303L271.79 87V138.048L361.121 196.097L350.612 229.351H271.79V271.648H350.612L361.121 304.903L271.79 362.952V414L411.042 322.989L388.271 250.646L411.042 178.303Z" fill="url(#paint0_linear_2_14)"/>
            <path d="M150.638 271.648H229.168V229.351H150.346L140.128 196.097L229.168 138.048V87L89.9159 178.303L112.687 250.646L89.9159 322.989L229.459 414V362.952L140.128 304.903L150.638 271.648Z" fill="url(#paint1_linear_2_14)"/>
            <mask id="mask0_2_14" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="89" y="87" width="323" height="327">
              <path d="M411.042 178.303L271.79 87.0001V138.048L361.121 196.097L350.612 229.352H271.79V271.649H350.612L361.121 304.903L271.79 362.952V414L411.042 322.989L388.271 250.646L411.042 178.303Z" fill="url(#paint2_linear_2_14)"/>
              <path d="M150.638 271.649H229.168V229.352H150.346L140.128 196.097L229.168 138.048V87.0001L89.9161 178.303L112.687 250.646L89.9161 322.989L229.46 414V362.952L140.128 304.903L150.638 271.649Z" fill="url(#paint3_linear_2_14)"/>
            </mask>
            <g mask="url(#mask0_2_14)">
              <path d="M408.913 87.0001H90.0877V414H408.913V87.0001Z" fill="url(#paint4_linear_2_14)"/>
            </g>
          </g>
          <defs>
          <linearGradient id="paint0_linear_2_14" x1="365.994" y1="436.481" x2="272.717" y2="51.089" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0B46F9"/>
            <stop offset="1" stop-color="#BBFBE0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_2_14" x1="365.994" y1="436.481" x2="272.717" y2="51.089" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0B46F9"/>
            <stop offset="1" stop-color="#BBFBE0"/>
          </linearGradient>
          <linearGradient id="paint2_linear_2_14" x1="365.994" y1="436.481" x2="272.717" y2="51.0891" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0B46F9"/>
            <stop offset="1" stop-color="#BBFBE0"/>
          </linearGradient>
          <linearGradient id="paint3_linear_2_14" x1="365.994" y1="436.481" x2="272.717" y2="51.0891" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0B46F9"/>
            <stop offset="1" stop-color="#BBFBE0"/>
          </linearGradient>
          <linearGradient id="paint4_linear_2_14" x1="110.525" y1="160.575" x2="271.982" y2="281.156" gradientUnits="userSpaceOnUse">
            <stop offset="0.119792" stop-color="#8952FF" stop-opacity="0.87"/>
            <stop offset="1" stop-color="#DABDFF" stop-opacity="0"/>
          </linearGradient>
          <clipPath id="clip0_2_14">
            <rect width="327" height="327" fill="white" transform="translate(86 87)"/>
          </clipPath>
          </defs>
        </svg>
        <span>Exodus</span>
      </a>
    </div>
  </div>

  <ng-container *ngIf="officialMempoolSpace">
    <div *ngIf="profiles$ | async as profiles" id="community-sponsors">
      <div class="community-sponsor" style="margin-bottom: 68px" *ngIf="profiles.whales.length > 0">
        <h3 i18n="about.sponsors.withHeart">Whale Sponsors</h3>
        <div class="wrapper">
          <ng-container>
            <ng-template ngFor let-sponsor [ngForOf]="profiles.whales">
              <a [href]="'https://twitter.com/' + sponsor.username" target="_blank" rel="sponsored" [title]="sponsor.username">
                <img class="image" [src]="'data:' + sponsor.image_mime + ';base64,' + sponsor.image" onError="this.src = '/resources/profile/unknown.svg'; this.className = 'image unknown'"/>
              </a>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div class="community-sponsor" style="margin-bottom: 68px" *ngIf="profiles.chads.length > 0">
        <h3 i18n="about.sponsors.withHeart">Chad Sponsors</h3>
        <div class="wrapper">
          <ng-template ngFor let-sponsor [ngForOf]="profiles.chads">
            <a [href]="'https://twitter.com/' + sponsor.username" target="_blank" rel="sponsored" [title]="sponsor.username">
              <img class="image" [src]="'data:' + sponsor.image_mime + ';base64,' + sponsor.image" onError="this.src = '/resources/profile/unknown.svg'; this.className = 'image unknown'"/>
            </a>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="community-sponsor" style="margin-bottom: 68px">
    <h3 i18n="about.sponsors.withHeart">OG Sponsors ❤️</h3>
    <div class="wrapper">
      <ng-container *ngIf="ogs$ | async as ogs; else loadingSponsors">
        <a *ngFor="let ogSponsor of ogs" [href]="'https://twitter.com/' + ogSponsor.handle" target="_blank" rel="sponsored" [title]="ogSponsor.handle">
          <img class="image" [src]="'/api/v1/donations/images/' + ogSponsor.handle" onError="this.src = '/resources/profile/unknown.svg'; this.className = 'image unknown'"/>
        </a>
      </ng-container>
    </div>
  </div>

  <div class="community-integrations-sponsor" id="community-integrations">
    <h3 i18n="about.community-integrations">Community Integrations</h3>
    <div class="wrapper">
      <a href="https://github.com/getumbrel/umbrel" target="_blank" title="Umbrel">
        <img class="image" src="/resources/profile/umbrel.png" />
        <span>Umbrel</span>
      </a>
      <a href="https://github.com/rootzoll/raspiblitz" target="_blank" title="RaspiBlitz">
        <img class="image" src="/resources/profile/raspiblitz.svg" />
        <span>RaspiBlitz</span>
      </a>
      <a href="https://github.com/mynodebtc/mynode" target="_blank" title="myNode">
        <img class="image" src="/resources/profile/mynodebtc.png" />
        <span>myNode</span>
      </a>
      <a href="https://code.samourai.io/ronindojo/RoninDojo" target="_blank" title="RoninDojo">
        <img class="image" src="/resources/profile/ronindojo.png" />
        <span>RoninDojo</span>
      </a>
      <a href="https://github.com/runcitadel" target="_blank" title="Citadel">
        <img class="image" src="/resources/profile/runcitadel.svg" />
        <span>Citadel</span>
      </a>
      <a href="https://github.com/fort-nix/nix-bitcoin" target="_blank" title="nix-bitcoin">
        <img class="image" src="/resources/profile/nix-bitcoin.png" />
        <span>NixOS</span>
      </a>
      <a href="https://github.com/Start9Labs/start-os" target="_blank" title="StartOS">
        <img class="image" src="/resources/profile/start9.png" />
        <span>StartOS</span>
      </a>
      <a href="https://github.com/btcpayserver/btcpayserver" target="_blank" title="BTCPay Server">
        <img class="image not-rounded" src="/resources/profile/btcpayserver.svg" />
        <span>BTCPay</span>
      </a>
      <a href="https://github.com/bisq-network/bisq" target="_blank" title="Bisq">
        <img class="image" src="/resources/profile/bisq_network.png" />
        <span>Bisq</span>
      </a>
      <a href="https://github.com/BlueWallet/BlueWallet" target="_blank" title="BlueWallet">
        <img class="image" src="/resources/profile/bluewallet.png" />
        <span>BlueWallet</span>
      </a>
      <a href="https://github.com/muun/apollo" target="_blank" title="Muun Wallet">
        <img class="image" src="/resources/profile/muun.png" />
        <span>Muun</span>
      </a>
      <a href="https://github.com/spesmilo/electrum" target="_blank" title="Electrum Wallet">
        <img class="image" src="/resources/profile/electrum.png" />
        <span>Electrum</span>
      </a>
      <a href="https://github.com/cryptoadvance/specter-desktop" target="_blank" title="Specter Wallet">
        <img class="image" src="/resources/profile/specter.png" />
        <span>Specter</span>
      </a>
      <a href="https://github.com/sparrowwallet/sparrow" target="_blank" title="Sparrow Wallet">
        <img class="image" src="/resources/profile/sparrow.png" />
        <span>Sparrow</span>
      </a>
      <a href="https://github.com/ACINQ/phoenix" target="_blank" title="Phoenix Wallet by ACINQ">
        <img class="image not-rounded" src="/resources/profile/phoenix.svg" />
        <span>Phoenix</span>
      </a>
      <a href="https://github.com/lnbits/lnbits-legend" target="_blank" title="LNbits">
        <img class="image" src="/resources/profile/lnbits.svg" />
        <span>LNBits</span>
      </a>
      <a href="https://github.com/layer2tech/mercury-wallet" target="_blank" title="Mercury Wallet">
        <img class="image" src="/resources/profile/mercury.svg" />
        <span>Mercury</span>
      </a>
      <a href="https://github.com/hsjoberg/blixt-wallet" target="_blank" title="Blixt Wallet">
        <img class="image" src="/resources/profile/blixt.png" />
        <span>Blixt</span>
      </a>
      <a href="https://github.com/ZeusLN/zeus" target="_blank" title="Zeus">
        <img class="image" src="/resources/profile/zeus.png" />
        <span>Zeus</span>
      </a>
      <a href="https://github.com/vulpemventures/marina" target="_blank" title="Marina Wallet">
        <img class="image" src="/resources/profile/marina.svg" />
        <span>Marina</span>
      </a>
      <a href="https://github.com/bitcoin-wallet/bitcoin-wallet/" target="_blank" title="Bitcoin Wallet (Schildbach)">
        <img class="image" src="/resources/profile/schildbach.svg" />
        <span>Schildbach</span>
      </a>
      <a href="https://github.com/nunchuk-io" target="_blank" title="Nunchuck">
        <img class="image" src="/resources/profile/nunchuk.svg" />
        <span>Nunchuk</span>
      </a>
      <a href="https://github.com/bitcoin-s/bitcoin-s" target="_blank" title="bitcoin-s">
        <img class="image" src="/resources/profile/bitcoin-s.svg" />
        <span>bitcoin-s</span>
      </a>
      <a href="https://github.com/EdgeApp" target="_blank" title="Edge">
        <img class="image not-rounded" src="/resources/profile/edge.svg" />
        <span>Edge</span>
      </a>
      <a href="https://github.com/GaloyMoney" target="_blank" title="Galoy">
        <img class="image" src="/resources/profile/galoy.svg" />
        <span>Galoy</span>
      </a>
      <a href="https://github.com/BoltzExchange" target="_blank" title="Boltz">
        <img class="image" src="/resources/profile/boltz.svg" />
        <span>Boltz</span>
      </a>
      <a href="https://github.com/MutinyWallet" target="_blank" title="Mutiny">
        <img class="image not-rounded" src="/resources/profile/mutiny.svg" />
        <span>Mutiny</span>
      </a>
    </div>
  </div>

  <div class="alliances" id="community-alliances">
    <h3 i18n="about.alliances">Community Alliances</h3>
    <div class="wrapper">
      <a href="https://liquid.net/" title="Liquid Network">
        <img class="liquid" src="/resources/profile/liquid.svg" />
      </a>
      <a href="https://opencrypto.org/" title="Coppa - Crypto Open Patent Alliance">
        <img class="copa" src="/resources/profile/copa.png" />
      </a>
      <a href="https://bisq.network/" title="Bisq Network">
        <img class="bisq" src="/resources/profile/bisq.svg" />
      </a>
    </div>
  </div>

  <ng-container *ngIf="translators$ | async | keyvalue as translators else loadingSponsors">
    <div class="project-translators" id="project-translators">
      <h3 i18n="about.translators">Project Translators</h3>
      <div class="wrapper">
        <ng-template ngFor let-translator [ngForOf]="translators">
          <a [href]="'https://twitter.com/' + translator.value" target="_blank" [title]="translator.key">
            <img class="image" [src]="'/api/v1/translators/images/' + translator.value" onError="this.src = '/resources/profile/unknown.svg'; this.className = 'image unknown'"/>
          </a>
        </ng-template>
      </div>
    </div>
    <br>
  </ng-container>

  <ng-container *ngIf="allContributors$ | async as contributors else loadingSponsors">
    <div class="contributors" id="project-contributors">
      <h3 i18n="about.contributors">Project Contributors</h3>
      <div class="wrapper">
        <ng-template ngFor let-contributor [ngForOf]="contributors.regular">
          <a [href]="'https://github.com/' + contributor.name" target="_blank" [title]="contributor.name">
            <img class="image" [src]="'/api/v1/contributors/images/' + contributor.id" onError="this.src = '/resources/profile/unknown.svg'; this.className = 'image unknown'"/>
            <span>{{ contributor.name }}</span>
          </a>
        </ng-template>
      </div>
    </div>

    <div class="maintainers" *ngIf="contributors.core.length" id="project-members">
      <h3 i18n="about.project_members">Project Members</h3>
      <div class="wrapper">
        <ng-template ngFor let-contributor [ngForOf]="contributors.core">
          <a [href]="'https://github.com/' + contributor.name" target="_blank" [title]="contributor.name">
            <img class="image" [src]="'/api/v1/contributors/images/' + contributor.id" onError="this.src = '/resources/profile/unknown.svg'; this.className = 'image unknown'"/>
            <span>{{ contributor.name }}</span>
          </a>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <div class="maintainers" id="project-maintainers">
    <h3 i18n="about.maintainers">Project Maintainers</h3>
    <div class="wrapper">
        <a href="https://twitter.com/softsimon_" target="_blank" title="softsimon">
          <img class="image" src="/resources/profile/softsimon.jpg" />
          <span>softsimon</span>
        </a>
        <a href="https://twitter.com/wiz" target="_blank" title="wiz">
          <img class="image" src="/resources/profile/wiz.png" />
          <span>wiz</span>
        </a>
    </div>
  </div>

  <div class="copyright">
    <div class="title">
      Copyright &copy; 2019-2023<br>
      The Mempool Open Source Project
    </div>
    <p>
      <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a> is free software; you can redistribute it and/or modify it under the terms of (at your option) either:<br>
    </p>
    <ul>
      <li>
        1) the <a href="https://www.gnu.org/licenses/agpl-3.0-standalone.html">GNU Affero General Public License</a> as published by the Free Software Foundation, either version 3 of the License or any later version approved by a proxy statement published on &lt;https://mempool.space/about&gt;; or<br>
      </li>
      <li>
        2) the <a href="https://www.gnu.org/licenses/gpl-3.0-standalone.html">GNU General Public License</a> as published by the Free Software Foundation, either version 3 of the License or any later version approved by a proxy statement published on &lt;https://mempool.space/about&gt;.<br>
      </li>
    </ul>
    <p>
      This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the full license terms for more details.<br>
    </p>
    <div class="title">
      Trademark Notice<br>
    </div>
    <p>
      The Mempool Open Source Project&reg;, mempool.space&trade;, the mempool logo&reg;, the mempool.space logos&trade;, the mempool square logo&reg;, and the mempool blocks logo&trade; are either registered trademarks or trademarks of Mempool Space K.K in Japan, the United States, and/or other countries.
    </p>
    <p>
      While our software is available under an open source software license, the copyright license does not include an implied right or license to use our trademarks. See our <a href="https://mempool.space/trademark-policy">Trademark Policy and Guidelines</a> for more details, published on &lt;https://mempool.space/trademark-policy&gt;.
    </p>
  </div>

  <div class="footer-links">
    <a href="/3rdpartylicenses.txt">Third-party Licenses</a>
  </div>

  <br>
</div>


<ng-template #loadingSponsors>
  <br>
  <div class="spinner-border text-light"></div>
</ng-template>

