<div
  #tooltip
  *ngIf="rbfInfo && tooltipPosition !== null"
  class="rbf-tooltip"
  [style.left]="tooltipPosition.x + 'px'"
  [style.top]="tooltipPosition.y + 'px'"
>
  <table>
    <tbody>
      <tr>
        <td class="td-width" i18n="shared.transaction">Transaction</td>
        <td>
          <a [routerLink]="['/tx/' | relativeUrl, rbfInfo.tx.txid]">{{ rbfInfo.tx.txid | shortenString : 16}}</a>
        </td>
      </tr>
      <tr>
        <td class="td-width" i18n="transaction.first-seen|Transaction first seen">First seen</td>
        <td><i><app-time kind="since" [time]="rbfInfo.time" [fastRender]="true"></app-time></i></td>
      </tr>
      <tr>
        <td class="td-width" i18n="transaction.fee|Transaction fee">Fee</td>
        <td>{{ rbfInfo.tx.fee | number }} <span class="symbol" i18n="shared.sat|sat">sat</span></td>
      </tr>
      <tr *only-vsize>
        <td class="td-width" i18n="transaction.vsize|Transaction Virtual Size">Virtual size</td>
        <td [innerHTML]="'&lrm;' + (rbfInfo.tx.vsize | vbytes: 2)"></td>
      </tr>
      <tr *only-weight>
        <td class="td-width" i18n="transaction.weight|Transaction Weight">Weight</td>
        <td [innerHTML]="'&lrm;' + (rbfInfo.tx.vsize * 4 | vbytes: 2)"></td>
      </tr>
      <tr>
        <td class="td-width" i18n="transaction.status|Transaction Status">Status</td>
        <td>
          <span *ngIf="rbfInfo.tx.fullRbf" class="badge badge-info" i18n="rbfInfo-features.tag.full-rbf|Full RBF">Full RBF</span>
          <span *ngIf="rbfInfo.tx.rbf; else rbfDisabled" class="badge badge-success" i18n="rbfInfo-features.tag.rbf|RBF">RBF</span>
          <ng-template #rbfDisabled><span class="badge badge-danger mr-1"><del i18n="rbfInfo-features.tag.rbf|RBF">RBF</del></span></ng-template>
          <span *ngIf="rbfInfo.tx.mined" class="badge badge-success" i18n="transaction.rbf.mined">Mined</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
